<template>
<div v-if="groups">
<ion-grid>
    <ion-row v-for="group in groups" :key="group.id">
        <ion-col>
            
            <ion-card>
                <ion-card-header>
                    <ion-card-subtitle>{{group.Id}}</ion-card-subtitle>
                    <ion-card-title>{{ group.Name }}</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                    {{group.Description}}
                </ion-card-content>

            </ion-card>    
                
        </ion-col>
    </ion-row>
</ion-grid>
</div>
<div v-if="!groups">
    <loading-template />
  </div>
</template>

<script>
import apiService from "@/services/apiService";
import { IonCol, IonGrid, IonRow, IonCard } from '@ionic/vue';
import LoadingTemplate from '@/components/System/Loading.vue'
import { Storage } from '@ionic/storage';
export default {
  name: "get-request",
  components: { IonCol, IonGrid, IonRow, IonCard, LoadingTemplate },
  data() {
    return {
      groups: null,
    };
  },
  created() {
    // Simple GET request using axios
    //Remember to give ROCK RMS Key Security Access in Gateway Rock
    const config = {
      headers: {
        "Accept": "application/json"
      }
    };

    apiService
      .get("https://portal.gatewaybaptist.com/api/Groups?$select=Id,Name,Description&$top=2000", config)
      .then((response) => (this.groups = response.data));
    
  },
  methods: {
    async getToken() {
      const storage = new Storage();
      await storage.create();
      const token = await storage.get({key: 'AuthToken'});
      //const token = await storage.keys();
      return token;
    }
  }
};
</script>
